.decrease-height {
  -webkit-animation-name: decrease-height;
  animation-name: decrease-height;
}
.grow-right {
  -webkit-animation-name: grow-right;
  animation-name: grow-right;
}
.increase-height {
  -webkit-animation-name: increase-height;
  animation-name: increase-height;
}
.move-down-margin-bottom {
  -webkit-animation-name: move-down-margin-bottom;
  animation-name: move-down-margin-bottom;
}
.move-up-margin-bottom {
  -webkit-animation-name: move-up-margin-bottom;
  animation-name: move-up-margin-bottom;
}
.move-down-margin-top {
  -webkit-animation-name: move-down-margin-top;
  animation-name: move-down-margin-top;
}
.move-search-right {
  -webkit-animation-name: move-search-right;
  animation-name: move-search-right;
  animation-duration: 300ms;
}
.move-search-left {
  -webkit-animation-name: move-search-left;
  animation-name: move-search-left;
  animation-duration: 300ms;
}
.move-up-margin-top {
  -webkit-animation-name: move-up-margin-top;
  animation-name: move-up-margin-top;
}
.slide-in-right {
  -webkit-animation-name: slide-in-right;
  animation-name: slide-in-right;
}
.slide-out-right {
  -webkit-animation-name: slide-out-right;
  animation-name: slide-out-right;
}
.slide-out-up {
  -webkit-animation-name: slide-out-up;
  animation-name: slide-out-up;
  animation-duration: 600ms;
}
@keyframes increase-height {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: hidden;
    height: 0;
  }
  20% {
    visibility: hidden;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    height: 100%;
  }
}
@keyframes decrease-height{
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    height: 100%;
  }
  10% {
    height: 5.5rem;
    visibility: hidden;
  }
  100% {
    height: 0;
    visibility: hidden;
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }
}
@keyframes grow-right {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}
@keyframes move-down-margin-bottom {
  from { margin-bottom: -6.4rem; }
  to { margin-bottom: 0; }
}
@keyframes move-up-margin-bottom {
  from { margin-bottom: 0; }
  to { margin-bottom: -6.4rem; }
}
@keyframes move-down-margin-top {
  from { margin-top: -1.8rem; }
  to { margin-top: 0.5rem }
}
@keyframes move-search-right {
  from {
    -webkit-transform: translate3d(-225%, 0, 0);
    transform: translate3d(-225%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@keyframes move-search-left {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-225%, 0, 0);
    transform: translate3d(-225%, 0, 0);
    visibility: hidden;
  }
}
@keyframes move-up-margin-top {
  from { margin-top: 0.5rem }
  to { margin-top: -1.8rem; }
}
@keyframes slide-in-right {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@keyframes slide-out-right {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
  }
}
@keyframes slide-out-up {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: hidden;
  }
}

@media screen and (max-width: 1000px) {
  @keyframes move-search-right {
    from {
      -webkit-transform: translate3d(-150%, 0, 0);
      transform: translate3d(-150%, 0, 0);
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  }
  @keyframes move-search-left {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    to {
      -webkit-transform: translate3d(-175%, 0, 0);
      transform: translate3d(-175%, 0, 0);
      visibility: hidden;
    }
  }
}

@media screen and (max-width: 800px) {
  @keyframes move-search-right {
    from {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  }
  @keyframes move-search-left {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    to {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
      visibility: hidden;
    }
  }
}
