/* This css is used to style base components such as html, body and typography elements*/
@import 'assets/fonts/icons/style.css';
@import 'assets/fonts/states/style.css';

:root {
  --color-primary: #0B7EA2;
  --color-secondary: #52803C;
  --color-font-primary: #232C3B;
  --color-greyscale-black: #000000;
  --color-greyscale-mirage: #151d24;
  --color-greyscale-iron: #343439;
  --color-greyscale-smoke: #66666d;
  --color-greyscale-silver: #ababaf;
  --color-greyscale-pewter: #c5c5c8;
  --color-greyscale-cloud: #dfdfe1;
  --color-greyscale-snow: #f3f3f9;
  --color-greyscale-snowWhite: #fafaff;
  --color-greyscale-white: #ffffff;
  --color-greyscale-overlay: rgba(0, 0, 0, 0.7);
  --color-blue-brightNavy: #2e6195;
  --color-blue-regatta: #00bfe7;
  --color-blue-hover: #244378;
  --color-green-default: #008358;
  --color-green-hover: #035740;
  --color-red-default: #d30000;
  --color-yellow-default: #ffcb00;
}

html,
body,
#root {
  color: var(--color-font-primary);
  background: var(--color-greyscale-snowWhite);
}
html {
  font-size: 62.5%;
}
body {
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.3rem;
  font-weight: normal;
  font-style: normal;
}

.display-none {
  display: none;
}

iframe:not(#_BH_frame) {
  width: 100% !important;
}

/*
  https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
  This removes the OUTLINE to every element listed here UNTIL the user starts using the tab.
  because there's a small javascript piece added in the index.html file to add the class
  'user-is-tabbing' to the body. Then, everything will have a normal outline until refresh.
*/
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) div:focus,
body:not(.user-is-tabbing) i:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) img:focus {
  outline: none;
  box-shadow: none;
}

body.user-is-tabbing button:focus,
body.user-is-tabbing input:focus,
body.user-is-tabbing select:focus,
body.user-is-tabbing div:focus,
body.user-is-tabbing i:focus,
body.user-is-tabbing textarea:focus,
body.user-is-tabbing a:focus,
body.user-is-tabbing img:focus {
  outline: 2px solid var(--color-secondary);
}

h1 {
  font-size: 3.2rem;
  margin: 1rem 0;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 2rem;
}
a {
  color: var(--color-primary);
  text-decoration: none;
  outline: none;
}
a:hover {
  text-decoration: none;
  color: var(--color-blue-hover);
}
a:focus {
  outline: 2px solid var(--color-secondary);
}
.modal-config-for-ads,
.prompt-modal {
  background-color: transparent;
  border: none;
}
input:disabled,
textarea:disabled {
  background-color: var(--color-greyscale-cloud);
}
.no-padding {
  padding: 0;
}
/* FONTS STYLING */
/*
  Font Weights
  300 light
  400 regular
  600 semi-bold
  700 bold
*/
.text-attribution-iron {
  color: var(--color-greyscale-iron);
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.08rem;
  line-height: 1.6rem;
  text-transform: uppercase;
}
.text-attribution {
  color: var(--color-greyscale-smoke);
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.08rem;
  line-height: 1.2rem;
  text-transform: uppercase;
}
.text-attribution-regular {
  color: var(--color-greyscale-smoke);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.08rem;
  line-height: 1.2rem;
  text-transform: uppercase;
}
.text-attribution-small {
  color: var(--color-greyscale-smoke);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2rem;
  text-transform: uppercase;
}
.text-by-line {
  color: var(--color-greyscale-smoke);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.6rem;
}
.text-sub-heading {
  color: var(--color-greyscale-smoke);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.023rem;
  line-height: 2.4rem;
}
.text-graphic-description {
  color: var(--color-greyscale-iron);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.023rem;
  line-height: 2rem;
}
.text-sub-heading-black {
  color: var(--color-font-primary);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.023rem;
  line-height: 2.4rem;
}
.text-breadcrumbs {
  color: var(--color-greyscale-smoke);
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.01rem;
  line-height: 2.4rem;
}
.text-sub-heading-small {
  color: var(--color-greyscale-smoke);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.023rem;
  line-height: 2.4rem;
}
.text-sub-heading-small-white {
  color: var(--color-greyscale-white);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.023rem;
  line-height: 2.4rem;
}
.text-sub-heading-bold-small-white {
  color: var(--color-greyscale-white);
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.023rem;
  line-height: 2.4rem;
}
.text-by-line-source {
  color: var(--color-greyscale-smoke);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.6rem;
}
.text-by-line-source-normal {
  color: var(--color-greyscale-smoke);
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.6rem;
}
.text-timestamp {
  color: var(--color-greyscale-smoke);
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.6rem;
}

.text-italic {
  font-style: italic;
}

/* Title, cards, list objects,  */
.text-headline-card {
  color: var(--color-font-primary);
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.8rem;
}
.text-headline-content {
  color: var(--color-font-primary);
  font-family: 'Inter', sans-serif;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 4.2rem;
}
.text-headline-poster {
  color: var(--color-font-primary);
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
}
.text-headline-poster-small {
  color: var(--color-font-primary);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
}
/* card users title description, schools for you  */
.text-content-section-head-white {
  color: var(--color-greyscale-snowWhite);
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.4rem;
}
.text-content-section-head {
  color: var(--color-font-primary);
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.8rem;
}
.text-content-section-head-regular {
  color: var(--color-greyscale-smoke);
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.8rem;
}
.text-content-section-head-blue {
  color: var (--color-primary);
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 2.8rem;
}
.text-content-section-head-black {
  color: var(--color-greyscale-black);
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 2.8rem;
}
.text-content-subhead {
  color: var(--color-font-primary);
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.4rem;
}
.text-content-subhead-small {
  color: var(--color-greyscale-smoke);
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.4rem;
}
.text-content-subhead-black {
  color: var(--color-greyscale-black);
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.4rem;
}
.text-content-body {
  color: var(--color-font-primary);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 3rem;
}
.text-content-post {
  color: var(--color-font-primary);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.6rem;
}
.text-score-chart {
  color: var(--color-font-primary);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6rem;
}
.text-score-big-chart {
  color: var(--color-greyscale-white);
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.4rem;
}
.text-school-bar-chart {
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-transform: capitalize;
}
/* Share and More Menu modals */
.text-more-menu {
  font-size: 1.4rem;
}
.text-share-menu {
  font-size: 1.4rem;
}

/* inside school cards for costs, settings */
.text-label {
  color: var(--color-greyscale-smoke);
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.6rem;
}

.text-description {
  color: var(--color-greyscale-smoke);
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.6rem;
}

.text-link,
.text-link:hover {
  color: var(--color-greyscale-cloud);
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.6rem;
}

.text-option-label {
  color: var(--color-greyscale-smoke);
  font-size: 1.6rem;
}

.text-change-image {
  color: var(--color-greyscale-white);
  font-size: 1.6rem;
}

/* School profile specific texts */
.text-grad-school-name {
  font-size: 1.6rem;
  color: var(--color-primary);
  font-weight: 600;
  line-height: 2rem;
}
.text-read-more {
  font-size: 1.8rem;
  color: var(--color-primary);
  font-weight: 400;
  line-height: 2.4rem;
  letter-spacing: -0.011rem;
}
.text-school-graph-title {
  font-size: 2.4rem;
  color: var(--color-primary);
  font-weight: 600;
  line-height: 2.8rem;
}

.text-school-graph-subtitle {
  font-size: 1.8rem;
  color: var(--color-primary);
  font-weight: 600;
  line-height: 2.4rem;
}

.text-school-graph-gender-title {
  font-size: 2.4rem;
  color: var(--color-primary);
  font-weight: 400;
  line-height: 3.6rem;
}

.text-school-graph-gender-subtitle {
  font-size: 4.8rem;
  color: var(--color-primary);
  font-weight: 600;
  line-height: 5.2rem;
}

.text-school-graph-ratio {
  font-size: 2.4rem;
  color: var(--color-primary);
  font-weight: 600;
}

/* Navigation, Menu Dropdowns, Switcher */
.text-navigation {
  color: var(--color-font-primary);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
}
.text-navigation-active {
  color: black;
  font-weight: 600;
}
.text-question-subtext {
  color: var(--color-greyscale-smoke);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.026rem;
  line-height: 1.8rem;
}
.caption {
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: -0.1rem;
}
.caption--bold {
  font-weight: 600;
}
.caption--huge {
  font-size: 4.8rem;
  line-height: 5.6rem;
  letter-spacing: -0.3rem;
}
.caption--xxlarge {
  font-size: 3.2rem;
  line-height: 4rem;
  letter-spacing: -0.2rem;
}
.caption--xlarge {
  font-size: 2.8rem;
}
.caption--large {
  font-size: 2.2rem;
}
.caption--small {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0;
}
.caption--xsmall {
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0;
}
.caption--xxsmall {
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0;
}
.caption--tiny {
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0;
}
.caption--caps {
  text-transform: uppercase;
}
.caption--secondary {
  font-size: 1.4rem;
  line-height: 3.2rem;
  letter-spacing: 0.2rem;
}
.container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.hidden-text-area {
  opacity: 0;
  z-index: -10;
}

::placeholder {
  color: var(--color-greyscale-silver);
}

.text-success-submission {
  font-size: 4.8rem;
  color: var(--color-green-default);
  font-weight: 600;
  line-height: 5.2rem;
}
/* TAB LINKS STYLE */
.tab__link {
  border-bottom: 0.2rem solid var(--color-greyscale-cloud);
  flex: 1;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-greyscale-mirage);
  padding: 0.9rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms;
}
.tab__link:hover {
  color: var(--color-primary);
  border-bottom: 0.2rem solid var(--color-primary);
  font-weight: 600;
}
.tab__link--active {
  border-bottom: 0.2rem solid var(--color-primary);
  color: var(--color-primary);
  pointer-events: none;
}

.tab__link-secondary {
  border-bottom: 0.2rem solid var(--color-greyscale-cloud);
  flex: 1;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-greyscale-mirage);
  padding: 0.9rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms;
}
.tab__link-secondary:hover {
  color: var(--color-secondary);
  border-bottom: 0.2rem solid var(--color-secondary);
  font-weight: 600;
}
.tab__link-secondary--active {
  border-bottom: 0.2rem solid var(--color-secondary);
  color: var(--color-secondary);
  pointer-events: none;
}

/* Font weight and color */
.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.modal-open {
  overflow: hidden !important;
}

/* Reactstrap custom modal classes */
@media (min-width: 576px) {
  .compliance-notice__modal {
    min-width: 56rem;
  }
}

.compliance-notice__modal__content {
  border-radius: 0.6rem;
}

.google-ad__modal {
  display: flex;
  justify-content: center;
}

.google-ad__modal__content {
  height: 50rem;
  width: 30rem;
  background: transparent;
  border: none;
  box-shadow: 0px 0px 10px rgba(20, 25, 33, 0.1);
}

.author-modal-wrapper {
  border-radius: 0.6rem;
  max-width: 48rem;
}

.modal__content--center {
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
}

.whats-next__scheduler-modal {
  background-color: unset;
  border: none;
}

/* PLUS 1200 */
@media (min-width: 1200px) {
  div[class^='col'] {
    padding-right: 6px;
    padding-left: 6px;
  }
  div[class^='col']:first-child {
    padding-left: 0;
  }
  div[class^='col']:last-child {
    padding-right: 0;
  }
  .row {
    margin: 0;
  }
}

/* 1200 - 992 */
@media (max-width: 1200px) {
  .row {
    margin: 0;
  }
}
/* 992 - 768 */
@media (max-width: 992px) {
  .row {
    margin: 0;
  }
}
/* 768 - 576 */
@media (max-width: 768px) {
  .row {
    margin: 0;
  }
}
/* 576 - 320 */
@media (max-width: 576px) {
  .row {
    margin: 0;
  }
  /* Share and More Menu modals */
  .text-more-menu {
    font-size: 1.6rem;
  }
  .text-share-menu {
    font-size: 1.8rem;
  }

  /* Group Detail Page */
  .group-members {
    margin-top: 1.5rem;
  }

  .text-success-submission {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
