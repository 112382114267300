@font-face {
  font-family: 'states';
  src:  url('states.eot?1s2zhb');
  src:  url('states.eot?1s2zhb#iefix') format('embedded-opentype'),
    url('states.ttf?1s2zhb') format('truetype'),
    url('states.woff?1s2zhb') format('woff'),
    url('states.svg?1s2zhb#states') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="state-"], [class*=" state-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'states' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.state-ak:before {
  content: "\e900";
}
.state-al:before {
  content: "\e901";
}
.state-ar:before {
  content: "\e902";
}
.state-az:before {
  content: "\e903";
}
.state-ca:before {
  content: "\e904";
}
.state-co:before {
  content: "\e905";
}
.state-ct:before {
  content: "\e906";
}
.state-dc:before {
  content: "\e907";
}
.state-de:before {
  content: "\e908";
}
.state-fl:before {
  content: "\e909";
}
.state-ga:before {
  content: "\e90a";
}
.state-gu:before {
  content: "\e90b";
}
.state-hi:before {
  content: "\e90c";
}
.state-ia:before {
  content: "\e90d";
}
.state-id:before {
  content: "\e90e";
}
.state-il:before {
  content: "\e90f";
}
.state-in:before {
  content: "\e910";
}
.state-ks:before {
  content: "\e911";
}
.state-ky:before {
  content: "\e912";
}
.state-la:before {
  content: "\e913";
}
.state-ma:before {
  content: "\e914";
}
.state-md:before {
  content: "\e915";
}
.state-me:before {
  content: "\e916";
}
.state-mi:before {
  content: "\e917";
}
.state-mn:before {
  content: "\e918";
}
.state-mo:before {
  content: "\e919";
}
.state-ms:before {
  content: "\e91a";
}
.state-mt:before {
  content: "\e91b";
}
.state-nc:before {
  content: "\e91c";
}
.state-nd:before {
  content: "\e91d";
}
.state-ne:before {
  content: "\e91e";
}
.state-nh:before {
  content: "\e91f";
}
.state-nj:before {
  content: "\e920";
}
.state-nm:before {
  content: "\e921";
}
.state-nv:before {
  content: "\e922";
}
.state-ny:before {
  content: "\e923";
}
.state-oh:before {
  content: "\e924";
}
.state-ok:before {
  content: "\e925";
}
.state-or:before {
  content: "\e926";
}
.state-pa:before {
  content: "\e927";
}
.state-ri:before {
  content: "\e928";
}
.state-sc:before {
  content: "\e929";
}
.state-sd:before {
  content: "\e92a";
}
.state-tn:before {
  content: "\e92b";
}
.state-tx:before {
  content: "\e92c";
}
.state-us:before {
  content: "\e92d";
}
.state-ut:before {
  content: "\e92e";
}
.state-va:before {
  content: "\e92f";
}
.state-vi:before {
  content: "\e930";
}
.state-vt:before {
  content: "\e931";
}
.state-wa:before {
  content: "\e932";
}
.state-wi:before {
  content: "\e933";
}
.state-wv:before {
  content: "\e934";
}
.state-wy:before {
  content: "\e935";
}
