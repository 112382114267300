@font-face {
  font-family: 'icons';
  src:  url('icons.eot?ugzs40');
  src:  url('icons.eot?ugzs40#iefix') format('embedded-opentype'),
    url('icons.ttf?ugzs40') format('truetype'),
    url('icons.woff?ugzs40') format('woff'),
    url('icons.svg?ugzs40#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add_text:before {
  content: "\e900";
}
.icon-arrow_down:before {
  content: "\e901";
}
.icon-arrow_left:before {
  content: "\e902";
}
.icon-arrow_right:before {
  content: "\e903";
}
.icon-arrow_up:before {
  content: "\e904";
}
.icon-askaquestion .path1:before {
  content: "\e905";
  color: rgb(0, 0, 0);
}
.icon-askaquestion .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-askaquestion .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-at:before {
  content: "\e908";
}
.icon-attach:before {
  content: "\e909";
}
.icon-bookmark_filled:before {
  content: "\e90a";
}
.icon-bookmark_outline:before {
  content: "\e90b";
}
.icon-check:before {
  content: "\e90c";
}
.icon-check_circle:before {
  content: "\e90d";
}
.icon-christian:before {
  content: "\e90e";
}
.icon-city:before {
  content: "\e90f";
}
.icon-classroom:before {
  content: "\e910";
}
.icon-close_clear:before {
  content: "\e911";
}
.icon-comment:before {
  content: "\e912";
}
.icon-create:before {
  content: "\e913";
}
.icon-crown_badge:before {
  content: "\e914";
}
.icon-dollar:before {
  content: "\e915";
}
.icon-emoji:before {
  content: "\e916";
}
.icon-experts:before {
  content: "\e917";
}
.icon-explaimation_circle:before {
  content: "\e918";
}
.icon-facebook:before {
  content: "\e919";
}
.icon-female:before {
  content: "\e91a";
}
.icon-filter:before {
  content: "\e91b";
}
.icon-groups:before {
  content: "\e91c";
}
.icon-home:before {
  content: "\e91d";
}
.icon-instagram:before {
  content: "\e91e";
}
.icon-jewish:before {
  content: "\e91f";
}
.icon-laptop:before {
  content: "\e920";
}
.icon-library:before {
  content: "\e921";
}
.icon-linkedin:before {
  content: "\e922";
}
.icon-location:before {
  content: "\e923";
}
.icon-locked:before {
  content: "\e924";
}
.icon-macaroni .path1:before {
  content: "\e925";
  color: rgb(46, 97, 149);
}
.icon-macaroni .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(0, 191, 231);
}
.icon-macaroni .path3:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(78, 224, 255);
}
.icon-macaroni .path4:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(78, 224, 255);
}
.icon-male:before {
  content: "\e929";
}
.icon-menu:before {
  content: "\e92a";
}
.icon-message:before {
  content: "\e92b";
}
.icon-more:before {
  content: "\e92c";
}
.icon-more_menu:before {
  content: "\e92d";
}
.icon-muslim:before {
  content: "\e92e";
}
.icon-my:before {
  content: "\e92f";
}
.icon-newspapers:before {
  content: "\e930";
}
.icon-notifications:before {
  content: "\e931";
}
.icon-phone:before {
  content: "\e932";
}
.icon-plus:before {
  content: "\e933";
}
.icon-plus_cancel:before {
  content: "\e934";
}
.icon-plus_circle:before {
  content: "\e935";
}
.icon-profile:before {
  content: "\e936";
}
.icon-quanda:before {
  content: "\e937";
}
.icon-rural:before {
  content: "\e938";
}
.icon-school-business:before {
  content: "\e939";
}
.icon-school-default:before {
  content: "\e93a";
}
.icon-school-education:before {
  content: "\e93b";
}
.icon-school-general:before {
  content: "\e93c";
}
.icon-school-health:before {
  content: "\e93d";
}
.icon-school-law:before {
  content: "\e93e";
}
.icon-school-medicine:before {
  content: "\e93f";
}
.icon-school-nursing:before {
  content: "\e940";
}
.icon-schools:before {
  content: "\e941";
}
.icon-school-social_work:before {
  content: "\e942";
}
.icon-search:before {
  content: "\e943";
}
.icon-share:before {
  content: "\e944";
}
.icon-star:before {
  content: "\e945";
}
.icon-student:before {
  content: "\e946";
}
.icon-subfolders:before {
  content: "\e947";
}
.icon-suburban:before {
  content: "\e948";
}
.icon-tag:before {
  content: "\e949";
}
.icon-tasks:before {
  content: "\e94a";
}
.icon-thumbsup:before {
  content: "\e94b";
}
.icon-town:before {
  content: "\e94c";
}
.icon-trash:before {
  content: "\e94d";
}
.icon-trending_down:before {
  content: "\e94e";
}
.icon-trending_flat:before {
  content: "\e94f";
}
.icon-trending_up:before {
  content: "\e950";
}
.icon-twitter:before {
  content: "\e951";
}
.icon-unlock:before {
  content: "\e952";
}
.icon-verified .path1:before {
  content: "\e953";
  color: rgb(0, 0, 0);
}
.icon-verified .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-web:before {
  content: "\e955";
}
.icon-write_edit:before {
  content: "\e956";
}
.icon-write_edit_box:before {
  content: "\e957";
}
